/* Form Container */
.form-container {
    display: flex;
    flex-direction: row;
}

/* Form Section */
.form-section {
    flex: 1;
    padding: 20px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.toast {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}