.photo-wall img {
    width: 200px;
    height: 200px;
}

.photo-wall {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.main {
    align-content: center;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.container {
    margin: 1rem;
    text-align: center;
}

.nav-logo {
    width: 50px;
    height: 50px;
}

.site-title {
    font-size: 2rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active {
    background-color: #D68154;
}

.nav li:hover {
    background-color: #76B8B5;
}

.accordion {
    list-style: none;
    width: 100%;
    transition: max-height 0.5s ease-in-out;
}

.accordion-item {
    border: 1px solid #ccc;
}

.accordion.active {
    /*max-height: 200%;*/
    max-height: calc(100vh - 50px);
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-item-title {
    width: 100%;
    margin: 0;
}

.accordion-item-btn {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border: 0;
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}

.accordion-item-btn::after {
    content: '';
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-left: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 100%;
    transition: transform 0.2s ease-in-out;
}

.accordion-item.active .accordion-item-btn::after {
    transform: rotate(-180deg);
}

.accordion-item-container {
    transition: height 0.2s ease-in-out;
    overflow: hidden;
}

.accordion-item-content {
    border-top: 1px solid #cccccc;
    padding: 15px 20px;
}